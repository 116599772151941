import React from "react";
import "./clients.css";
import Slider from "react-infinite-logo-slider";
import Slideshow from "./Slideshow";
export default function Clients() {
  const images = [
    "./images/slider/1.png",
    "./images/slider/2.png",

  ];
  return (
    <> 
      <div className="clients-container">
     
         <h1>Top Hiring Companies</h1> <div className="clients-slider">
          <Slider
            width="320px"
            duration={70}
            blurBorders={false}
            blurBoderColor={"#fff"}
          >
            <Slider.Slide>
              <img
                src="./images/clients/client1.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/client2.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/amazon.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/atl.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/capgemini.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/google.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/hcl.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/inm.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/ms.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/oracle.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/soti.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/wipro.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/wm.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/client6.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>

            <Slider.Slide>
              <img
                src="./images/clients/client7.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/partner.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/tcs.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/accenture.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/capgemini.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/deltax.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/infosys.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/soti.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/tm.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
            <Slider.Slide>
              <img
                src="./images/clients/wipro.png"
                alt="any"
                className="w-36"
              />
            </Slider.Slide>
          </Slider>
        </div> 
      </div>
    </>
  );
}
