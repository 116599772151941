import React from 'react'
import Slideshow from './Slideshow'
import Courses from './Courses'

export default function MainCourses() {
    const images = [
        "./images/slider/1.png",
        "./images/slider/2.png",
  
      ];
  return (
    <>
    
    <Courses/>
    </>
  )
}
